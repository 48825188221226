import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import {AOJ_ICPC_API} from "./config";

function App() {
  const [msg, setMsg] = useState("");

  useEffect(() => {
    fetch(AOJ_ICPC_API)
        .then(r => r.text())
        .then(msg => setMsg(msg));
  });

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
          msg: {msg}
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
